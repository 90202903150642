// Wait for the page to be ready





export class DummyTracker {
  constructor (config, cb) {

    //[[{"x":7,"y":1,"col":"c1"}],[{"x":6,"y":2,"col":"c1"}],[{"x":5,"y":1,"col":"c2"}],[{"x":4,"y":3,"col":"c2"}],[],[],[],[]]

    this.grid = [
      {x:1, y:.1, col: "red"}, //@fixme: /order is reversed! fix from tracker
      {x:0.8, y:.2, col: "red"},
      {x:0.6, y:.1, col: "red"},
      {x:0.4, y:.2, col: "yellow"},
      {x:0.4, y:.4, col: "red"},
      {x:0.2, y:.2, col: "red"}
    ]
    this.grid_b = [
      { "x": 0, "y": 7.2, "col": "red" },
      { "x": 1.6, "y": 6.4, "col": "red" },
      { "x": 3.2, "y": 7.2, "col": "red" },
      { "x": 4.8, "y": 6.4, "col": "yellow" },
      { "x": 4.8, "y": 4.8, "col": "red" },
      { "x": 6.4, "y": 6.4, "col": "red" }]

    var that = this;
    setTimeout(cb.bind(this, that), 2000);
    

    //this.grid = [[],[{"x":1,"y":2,"col":"c1"}],[{"x":2,"y":3,"col":"c2"}],[{"x":3,"y":4,"col":"c1"}],[{"x":4,"y":2,"col":"c2"}],[{"x":5,"y":3,"col":"c1"}],[{"x":6,"y":2,"col":"c1"}],[{"x":7,"y":2,"col":"c1"},{"x":7,"y":3,"col":"c1"}]];
  };
  get(){
    return JSON.parse(JSON.stringify(this.grid));
  }

  

}

export const Tracker = function(config, cb) {

  console.log("Page loaded!");
  var that = this;

  // Store the color we will be tracking (selectable by clicking on the webcam feed)
  var color = {r: 124, g: 2, b: 12}; //red
  var color2 = {r: 164, g: 130, b: 2}; //yellow

  // Grab reference to the tags we will be using
  var slider = document.getElementById("tolerance");
  var canvas  = document.getElementById('canvas');
  var canvas2  = document.getElementById('canvas2');
  
  var context = canvas.getContext('2d');
  var context2 = canvas2.getContext('2d');
  var webcam = document.getElementById('webcam');
  var swatch = document.getElementById("color");

  // Register our custom color tracking function
  tracking.ColorTracker.registerColor('dynamic', function(r, g, b) {
    return getColorDistance(color, {r: r, g: g, b: b}) < slider.value
  });
  tracking.ColorTracker.registerColor('dynamic2', function(r, g, b) {
    return getColorDistance(color2, {r: r, g: g, b: b}) < slider.value
  });
  


  var bricks = {
    c1: [],
    c2: []
  }
  var GRID = [];
  
  function updateBricks(col, centers, cb){
    bricks[col] = centers;
    cb(that);
  }
  
  this.get = function(toGrid) {
    var notes = [];
    var grid = [];
    /*for (var g=0; g<config.patternLength; g++) {
      grid[g] = [];
    }*/ 
    for (var i in bricks) {
      for (var j in bricks[i]) {
        var b = bricks[i][j];
        var x = b.x / config.videoWidth;
        var y = b.y / config.videoWidth;
        if(toGrid) {
          x = Math.round(b.x / config.videoWidth * config.patternLength);
          y = Math.round(b.y / config.videoHeight * config.patternLength);
        }
        var note = {
          x: x,
          y: y,
          col: i
        }
        notes.push(note);
        grid.push(note);
      }
    }
    return grid;
  }

  var mediaConstraints =  {
    video: {
      width: { exact: config.videoWidth || 320 },
      height: { exact: config.videoHeight || 240 }
    },
    audio: false
  }

  // Create the color tracking object
  var tracker = new tracking.ColorTracker("dynamic");
  var tracker2 = new tracking.ColorTracker("dynamic2");

  // Add callback for the "track" event
  tracker.on('track', function(e) {

    context.clearRect(0, 0, canvas.width, canvas.height);

    if (e.data.length !== 0) {
      var centers = [];
      e.data.forEach(function(rect) {
        
        // console.log(rect);
        drawRect(rect, context, color);
        centers.push(center(rect));
      });
      updateBricks("red", centers, cb);
    }
    else {
      updateBricks("red", [], cb);
    }

  });
  // Add callback for the "track" event
  tracker2.on('track', function(e) {

    context2.clearRect(0, 0, canvas.width, canvas.height);

    if (e.data.length !== 0) {
      var centers = [];
      e.data.forEach(function(rect) {
        
        // console.log(rect);
        drawRect(rect, context, color);
        centers.push(center(rect));
      });
      updateBricks("yellow", centers, cb);
    }
    else {
      updateBricks("yellow", [], cb);
    }

  });

  // Start tracking
  tracking.track(webcam, tracker, { camera: true, mediaConstraints: mediaConstraints }, config.camId );
  tracking.track(webcam, tracker2, { camera: true, mediaConstraints: mediaConstraints }, config.camId );

  // Add listener for the click event on the video
  webcam.addEventListener("click", function (e) {

    // Grab color from the video feed where the click occured
    var c = getColorAt(webcam, e.offsetX, e.offsetY);

    // Update target color
    color.r = c.r;
    color.g = c.g;
    color.b = c.b;

    // Update the div's background so we can see which color was selected
    swatch.style.backgroundColor = "rgb(" + c.r + ", " + c.g + ", " + c.b + ")";

  });

};

// Calculates the Euclidian distance between the target color and the actual color
function getColorDistance(target, actual) {
  return Math.sqrt(
    (target.r - actual.r) * (target.r - actual.r) +
    (target.g - actual.g) * (target.g - actual.g) +
    (target.b - actual.b) * (target.b - actual.b)
  );
}

// Returns the color at the specified x/y location in the webcam video feed
function getColorAt(webcam, x, y) {

  // To be able to access pixel data from the webcam feed, we must first draw the current frame in
  // a temporary canvas.
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  canvas.width = webcam.width;
  canvas.height = webcam.height;
  context.drawImage(webcam, 0, 0, webcam.width, webcam.height);

  // Then we grab the pixel information from the temp canvas and return it as an object
  var pixel = context.getImageData(x, y, 1, 1).data;
  return {r: pixel[0], g: pixel[1], b: pixel[2]};

}

// Draw a colored rectangle on the canvas
function drawRect(rect, context, color) {
  context.strokeStyle = "rgb(" + color.r + ", " + color.g + ", " + color.b + ")";
  context.strokeRect(rect.x, rect.y, rect.width, rect.height);
}


function center(rect) {
  return {x:rect.x + rect.width/2, y: rect.y + rect.height/2};
}
